import React from 'react';
import { Link } from 'gatsby';
import {  useTheme } from '@mui/material/styles';
import { useMediaQuery} from '@mui/material';

import * as styles from './navigationHeader.module.css';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const NavigationHeader = ({ brands, activeBrand }) => {
    const dataByBrandName = groupBy(brands, 'brandName');
    const brName = Object.keys(dataByBrandName).reverse();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    

    return (
        <nav
            role="navigation"
            className={styles.container}
            aria-label="Main"
            style={{ borderBottom: '1px solid lightgrey', padding: '0px', marginTop: '50px' }}
        >
            <ul className={styles.navigation} style={{ margin: isSmall ? '0px' : '',padding: isSmall ? '0px' : '', }}>
                {brName &&
                    brName.map((b, index) => (
                        <li className={styles.navigationItem} style={{ marginRight: isSmall ? '10px' : '50px', }} key={`nav-${index}`}>
                            <Link
                                id={`btn-supp-device-model`}
                                to={`/supported-phones/${b}`}
                                className={`${activeBrand === b ? 'activeMenu' : ''}`}
                                activeClassName="active"
                                activeStyle={{ color: '#6b8bffd9', borderBottom: '4px solid' }}
                            >
                                {b}
                            </Link>
                        </li>
                    ))}
            </ul>
        </nav>
    );
    /*<nav role="navigation" className={styles.container} aria-label="Main" 
    style={{ borderBottom: '1px solid lightgrey',padding:'0px',marginTop:'50px' }}>
  <ul className={styles.navigation}>
    <li className={styles.navigationItem} style={{ marginRight:'50px' }}>
      <Link to="/Apple" activeClassName="active1"  activeStyle={{ color: '#6b8bffd9',borderBottom: '4px solid' }}>
        Apple
      </Link>
    </li>
    <li className={styles.navigationItem}>
      <Link to="/Samsung/" activeClassName="active" activeStyle={{ color: '#6b8bffd9',borderBottom: '4px solid' }}>
      Samsung
      </Link>
    </li>
  </ul>
</nav>*/
};

export default NavigationHeader;
