import * as React from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from './ContainerComponent';
import * as styles from './ArticlePreview.module.css';
import Button from '@mui/material/Button';
import Navigation from './NavigationHeaderComponent';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { redirectWithParams } from '../utils/Utils.js';

const getModelName = (title) => {
    var str = title;
    var part2 = str.slice(str.indexOf(' ') + 1);
    return (
        <span className="text-black">
            <span className="text-purple"> {part2}</span>
        </span>
    );
};

const buttonLabel = 'View Insurance Plans';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
}));

const ArticlePreview = ({ posts, brands, activeBrand }) => {
    // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
    const domainUrl = '';

    if (!posts) return null;
    if (!Array.isArray(posts)) return null;

    return (
        <>
            <Navigation brands={brands} activeBrand={activeBrand}></Navigation>
            <Container>
                <Grid container spacing={2}>
                    {posts.map((post, index) => {
                        const reviewUrl =
                            domainUrl +
                            `/supported-phones/${post?.brandName}/${post.title.replaceAll(' ', '-')}/Review`;
                        const repairUrl =
                            domainUrl +
                            `/supported-phones/${post?.brandName}/${post?.title.replaceAll(' ', '-')}/Screen-Repair`;
                        const specUrl =
                            domainUrl +
                            `/supported-phones/${post?.brandName}/${post?.title.replaceAll(' ', '-')}/Specifications`;
                        const articleUrl =
                            domainUrl +
                            `/supported-phones/${post?.brandName}/${post?.title.replaceAll(' ', '-')}/Articles`;

                        return (
                            <Grid item xs={12} sm={6} md={6} spacing={2}>
                                <Item>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} md={3}>
                                            <Box m={2} style={{ width: '20%', float: 'left' }}>
                                                <div className="" style={{ width: '65px', float: 'left' }}>
                                                    <GatsbyImage
                                                        image={getImage(post.reviewVideo)}
                                                        alt={post.title || 'Preview for this device'}
                                                    />
                                                </div>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} md={9}>
                                            <Box>
                                                <Typography align="left">
                                                    <Link
                                                        id={`btn-supp-device-model`}
                                                        underline="none"
                                                        href={reviewUrl}
                                                        className={styles.link}
                                                    >
                                                        <Box
                                                            component="h2"
                                                            mb={0}
                                                            sx={{ color: 'text.primary', fontSize: 18 }}
                                                        >
                                                            {getModelName(post.title)}
                                                        </Box>
                                                    </Link>
                                                    <Box mb={1}>
                                                        {post.variants &&
                                                            post.variants.map((b) => (
                                                                <Box
                                                                    mr={1}
                                                                    p={0.5}
                                                                    component="span"
                                                                    sx={{
                                                                        border: '1px solid #E5E7EB',
                                                                        fontSize: 14,
                                                                        color: '#424142',
                                                                    }}
                                                                >
                                                                    {b}{' '}
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                    <Box component="div" sx={{ fontSize: 14, color: '#6B8BFF' }} mb={2}>
                                                        <Link
                                                            id={'btn-supp-phone-review'}
                                                            mr={2}
                                                            href={reviewUrl}
                                                            sx={{ color: '#6B8BFF', textDecorationColor: '#6B8BFF' }}
                                                        >
                                                            Review
                                                        </Link>
                                                        <Link
                                                            id={'btn-supp-phone-specs'}
                                                            mr={2}
                                                            href={specUrl}
                                                            sx={{ color: '#6B8BFF', textDecorationColor: '#6B8BFF' }}
                                                        >
                                                            Tech Specs
                                                        </Link>
                                                        <Link
                                                            id={'btn-supp-phone-repairs'}
                                                            mr={2}
                                                            href={repairUrl}
                                                            sx={{ color: '#6B8BFF', textDecorationColor: '#6B8BFF' }}
                                                        >
                                                            Repair
                                                        </Link>
                                                        <Link
                                                            id={'btn-supp-phone-repairs'}
                                                            mr={2}
                                                            href={articleUrl}
                                                            sx={{ color: '#6B8BFF', textDecorationColor: '#6B8BFF' }}
                                                        >
                                                            Articles
                                                        </Link>
                                                    </Box>
                                                    <Box mt={2} sx={{ fontSize: 12 }}>
                                                        <Button
                                                            id="btn-supp-phone-insurance-plans"
                                                            variant="outlined"
                                                            style={{ color: '#8223D2', borderColor: '#E5E7EB' }}
                                                        >
                                                            <Link
                                                                style={{
                                                                    color: '#8223D2',
                                                                    borderColor: '#E5E7EB',
                                                                    fontSize: '12px',
                                                                    fontWeight: '600',
                                                                }}
                                                                underline="none"
                                                                href={`${
                                                                    process.env.GATSBY_EXTERNAL_BUYFLOW_URL
                                                                }?device=${
                                                                    post.title
                                                                }&plan=lite&click=enroll&src_site=${
                                                                    process.env.GATSBY_DOMAIN_URL
                                                                }/supported-phones/${
                                                                    post.brandName
                                                                }/${post.title.replaceAll(' ', '-')}`}
                                                            >
                                                                {buttonLabel.toUpperCase()}
                                                            </Link>
                                                        </Button>
                                                    </Box>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </>
    );
};

export default ArticlePreview;
